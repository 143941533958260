export function setOrdinalArrayIndex<T>(defaultValue: T, initialArray: T[], setIndex: number, value: T): T[] {
  const initialArrayLength = initialArray.length;
  const newArrayLength = Math.max(initialArrayLength, setIndex + 1);
  const updatedArray = new Array(newArrayLength);

  for (let i = 0; i < newArrayLength; i++) {
    if (i === setIndex) {
      updatedArray[i] = value;
    } else if (i < initialArrayLength) {
      updatedArray[i] = initialArray[i];
    } else {
      updatedArray[i] = defaultValue;
    }
  }

  return updatedArray;
}
