import { last, pick } from "lodash";
import { RRule } from "rrule";

import {
  IRRuleManagerEventFeedbackConfig,
  IRRuleManagerJson,
  IScheduleConfig,
  ISkip,
  periodUnitsToRRuleFrequency,
  RRuleManagerStructure,
  SkipArrValue,
} from "./RRuleManagerStructure";
import { genSkipRemove, getNextDates, getNextDatesUntil, isEventSkipped } from "./utils";
import { getCorrectedRRule, RRuleAnchor } from "./utils/getCorrectedRRule";
import { nowUTC, toUTCDate } from "../../utils/dateUtils";
import { setOrdinalArrayIndex } from "../../utils/setOrdinalArrayIndex";
import { takeUntilLast } from "../../utils/takeUntilLast";
import { VALID_DAYS } from "../Organization";

export interface ScheduledEvent {
  indexFromNext: number;
  indexFromScheduleStart: number;
  date: Date;
  isSkipped: boolean;
  paymentMultipleDueOnDate: number;
}

//
// this class is a decorated version of RRules which incorporates event feedback
// we make this immutable to avoid errors related to immutability
//
export class RRuleManager extends RRuleManagerStructure {
  protected constructor(
    rruleManagerJson: IRRuleManagerJson,
    vendorSchedule: RRule | Readonly<RRule>,
    useStartDateAsNow = false,
    prevRRuleManager?: RRuleManagerStructure
  ) {
    super(rruleManagerJson, vendorSchedule, useStartDateAsNow, prevRRuleManager);
  }

  private static _fromJson(
    rruleManagerJson: IRRuleManagerJson,
    vendorSchedule: RRule,
    useStartDateAsNow = false
  ) {
    return new RRuleManager(rruleManagerJson, vendorSchedule, useStartDateAsNow);
  }

  static fromJson(rruleManagerJson: IRRuleManagerJson, vendorSchedule: RRule) {
    return RRuleManager._fromJson(rruleManagerJson, vendorSchedule);
  }

  /**
   * Hacky function used in places where we create schedule without JSON. It is used in conversion
   * from Shopify contract, in Recharge migration and (most of all) - in old unit tests.
   *
   * The primary reason why it is used in tests, is because it uses schedule start date instead of now,
   * so tests are not dependant on current date and time. I am not sure whether this is an intended side
   * effect when we convert schedule from Shopify or migrate data from Recharge.
   *
   * Don't use this function in any new code - neither in production code, nor in any new tests. If you
   * need to build RRuleManager from parameters, use fromJson method instead.
   *
   * @param startDate - date and time when schedule starts.
   * @param initialSubmissionDate - date and time when the origin order was placed (or will be placed).
   * @param scheduleConfig - schedule parameters.
   * @param anchor - anchors in RRule format.
   * @param vendorSchedule - merchant billing schedule.
   * @param eventFeedbackOptions - obsolete options that control behavior of subscriptions with limited lifecycle.
   */
  static fromScheduleConfig(
    startDate: Date,
    initialSubmissionDate: Date,
    scheduleConfig: IScheduleConfig,
    anchor: RRuleAnchor,
    vendorSchedule: RRule,
    eventFeedbackOptions: Partial<IRRuleManagerEventFeedbackConfig> = {
      extendOnUserSkip: false,
      extendOnHiddenSkip: false,
      ignorePastIndicesWhenExtending: false,
    }
  ): RRuleManager {
    // corrects rrule for end of month dates (for monthly) and leap year (for yearly)
    const deliveryRRule = getCorrectedRRule(
      {
        dtstart: toUTCDate(startDate),
        freq: periodUnitsToRRuleFrequency(scheduleConfig.frequencyUnit),
        interval: scheduleConfig.deliveryFrequencyValue,
        until: scheduleConfig.deliveryUntilDate,
        count: scheduleConfig.deliveryUntilCount,
      },
      anchor
    );

    const useStartDateAsNow = true;
    return RRuleManager._fromJson(
      {
        extendOnUserSkip: !!eventFeedbackOptions.extendOnUserSkip,
        extendOnHiddenSkip: !!eventFeedbackOptions.extendOnHiddenSkip,
        ignorePastIndicesWhenExtending: !!eventFeedbackOptions.ignorePastIndicesWhenExtending,
        deliveryFrequencyMultiple: 1,
        paymentFrequencyMultiple: scheduleConfig.paymentFrequencyValue / scheduleConfig.deliveryFrequencyValue,
        deliveryRRuleString: RRule.optionsToString(deliveryRRule.origOptions),
        skips: [],
        initialSequenceStartDate: toUTCDate(initialSubmissionDate),
        billedCycleStartDate: undefined,
        billedDeliveriesCount: undefined,
        totalOrdersCount: undefined,
        totalBillingCycles: undefined,
        minCycles: scheduleConfig.minCycles ?? undefined,
        maxCycles: scheduleConfig.maxCycles ?? undefined,
        orderCycleIndex: scheduleConfig.orderCycleIndex ?? undefined,
      },
      vendorSchedule,
      useStartDateAsNow
    );
  }

  private copy = ({ deliveryRRule, ...otherProps }: Partial<RRuleManager>) => {
    const rrule = deliveryRRule ?? this.deliveryRRule;

    const useStartDateAsNow = false;
    return new RRuleManager(
      {
        ...this.config,
        ...otherProps,
        deliveryRRuleString: RRule.optionsToString(rrule.origOptions),
      },
      this.vendorSchedule,
      useStartDateAsNow,
      this
    );
  };

  // the text for the UI should ignore RRule correction
  toText = () =>
    new RRule({
      dtstart: this.deliveryRRule.origOptions.dtstart,
      freq: this.deliveryRRule.origOptions.freq,
      interval: this.deliveryRRule.origOptions.interval,
      until: this.deliveryRRule.origOptions.until,
      count: this.deliveryRRule.origOptions.count,
    }).toText();

  withNewRRule = (deliveryRRule: RRule | Readonly<RRule>): RRuleManager => this.copy({ deliveryRRule });

  withNewBilledCycle = (
    billedCycleStartDate: Date | undefined,
    billedDeliveriesCount: number | undefined
  ): RRuleManager => {
    return this.copy({ billedCycleStartDate, billedDeliveriesCount });
  };

  private withNewSkipArr = (skips: SkipArrValue[]) =>
    this.copy({
      skips: takeUntilLast(skips, arrValue => !!arrValue?.hiddenSkipped || !!arrValue?.userSkipped),
    });

  equals = (otherRRuleMgr: RRuleManager): boolean => {
    const otherComparisonFields = pick(otherRRuleMgr.deliveryRRule.options, ["count", "interval", "freq"]);
    const thisComparisonFields = pick(this.deliveryRRule.options, ["count", "interval", "freq"]);

    return (
      otherComparisonFields.count === thisComparisonFields.count &&
      otherComparisonFields.interval === thisComparisonFields.interval &&
      otherComparisonFields.freq === thisComparisonFields.freq &&
      otherRRuleMgr.extendOnUserSkip === this.extendOnUserSkip &&
      otherRRuleMgr.extendOnHiddenSkip === this.extendOnHiddenSkip &&
      otherRRuleMgr.ignorePastIndicesWhenExtending === this.ignorePastIndicesWhenExtending &&
      otherRRuleMgr.futureHiddenSkipsCount === this.futureHiddenSkipsCount
    );
  };

  skipNth = (index: number, hiddenSkip = false) => {
    const startingSkipValue = this.skips[index] || null;
    const nextSkipValue: SkipArrValue = {
      userSkipped: !hiddenSkip || !!startingSkipValue?.userSkipped,
      hiddenSkipped: hiddenSkip || !!startingSkipValue?.hiddenSkipped,
    };

    // get rid of null values at the end
    return this.withNewSkipArr(setOrdinalArrayIndex<SkipArrValue>(null, this.skips, index, nextSkipValue));
  };

  unskipNth = (index: number, hiddenUnskip = false) => {
    const startingSkipValue = this.skips[index] || null;
    let nextSkipValue: SkipArrValue = null;
    if (startingSkipValue) {
      const { userSkipped, hiddenSkipped } = startingSkipValue;
      if (hiddenUnskip) {
        nextSkipValue = { userSkipped: !!userSkipped, hiddenSkipped: false };
      } else {
        nextSkipValue = { userSkipped: false, hiddenSkipped: !!hiddenSkipped };
      }
    }

    // get rid of null values at the end
    return this.withNewSkipArr(setOrdinalArrayIndex<SkipArrValue>(null, this.skips, index, nextSkipValue));
  };

  get futureSkips(): SkipArrValue[] {
    return this.skips.slice(this.getPresentScheduleGeneratorIndex());
  }

  get futureHiddenSkipsCount(): number {
    return this.futureSkips.reduce((accum, v) => accum + (v?.hiddenSkipped ? 1 : 0), 0);
  }

  /**
   * Changes date of next delivery to a specified date. The intent is to keep all addons/skips/gifts/etc.
   * that were configured for the next delivery and subsequent deliveries. The implementation is:
   * 1. We move start date of the schedule to the specified date.
   * 2. We move last billed date to the new schedule start date.
   * 3. We calculate how many paid deliveries we have from the last bill and reduce the remaining number
   *    so that we have only the count of paid deliveries after the new start date.
   * 4. We chop off skips before index of the next delivery, so that new skips array is aligned with the
   *    new start date.
   */
  offsetNextDelivery(targetDate: Date, anchor: RRuleAnchor): RRuleManager {
    const [deliveryToMove] = this.getFutureScheduledDeliveries(1);

    // Build new RRule where start date is shifted to the target date
    const newRRule = getCorrectedRRule(
      {
        ...this.deliveryRRule.origOptions,
        dtstart: toUTCDate(targetDate),
      },
      anchor
    );

    // Remove everything before next delivery's index in the skip array, so that our old
    // next delivery will match index 0 in the new array
    const newSkips = this.skips.slice(deliveryToMove.indexFromScheduleStart);

    // If we have any remaining paid deliveries, then we will track them as billed cycle that starts
    // in the beginning of our new schedule. If there are no any remaining paid deliveries, then the
    // new schedule won't have last billing cycle, it's billing cycle is yet to take place
    const remainingPaidDeliveriesCount = this.getRemainingPaidDeliveriesCount();

    let billedCycleStartDate: Date | undefined;
    let billedDeliveriesCount: number | undefined;
    if (remainingPaidDeliveriesCount > 0) {
      billedCycleStartDate = toUTCDate(newRRule.options.dtstart);
      billedDeliveriesCount =
        this.paymentFrequencyMultiple > 1 ? this.paymentFrequencyMultiple : remainingPaidDeliveriesCount;
    }

    return this.copy({
      deliveryRRule: newRRule,
      skips: newSkips,
      billedCycleStartDate,
      billedDeliveriesCount,
    });
  }

  // TODO: How can we ensure nextActualDelivery & nextScheduledDelivery adhere to shops' billing days without passing billing days as a param
  get nextActualDelivery(): ScheduledEvent | null {
    const [futureDelivery] = this.getFutureActualDeliveries(1);
    return futureDelivery ?? null;
  }

  get nextScheduledDelivery(): ScheduledEvent | null {
    const [futureDelivery] = this.getFutureCalculatedDeliveries(1);
    return futureDelivery ?? null;
  }

  /**
   * Returns nearest schedule event when billing should occur.
   */
  getNextBillingEvent(): ScheduledEvent | null {
    // NOTE: 12 deliveries is not enough if we have large enough interval on prepaid subscription
    const nearestBillingEvent = this.getFutureActualDeliveries(12).find(_ => _.paymentMultipleDueOnDate > 0);
    return nearestBillingEvent ?? null;
  }

  getFutureActualDeliveries(numberOfFutureDeliveriesToGenerate: number, orgBillingDays = VALID_DAYS) {
    return this.generateFutureScheduleWithVendorSchedule(
      numberOfFutureDeliveriesToGenerate,
      {
        hiddenSkipped: false,
        userSkipped: false,
      },
      orgBillingDays
    );
  }

  // exclude "hidden skipped" and include "user skipped" deliveries
  getFutureScheduledDeliveries(numberOfFutureDeliveries: number, orgBillingDays = VALID_DAYS) {
    return this.generateFutureScheduleWithVendorSchedule(
      numberOfFutureDeliveries,
      {
        hiddenSkipped: false,
        userSkipped: true,
      },
      orgBillingDays
    );
  }

  // exclude "hidden skipped" and include "user skipped" deliveries
  getFutureScheduledDeliveriesUntilDate(date: Date, orgBillingDays = VALID_DAYS) {
    return this.generateFutureScheduleWithVendorSchedule(
      date,
      {
        hiddenSkipped: false,
        userSkipped: true,
      },
      orgBillingDays
    );
  }

  // include "hidden skipped" and "user skipped" deliveries
  getFutureCalculatedDeliveries(numberOfFutureDeliveries: number, orgBillingDays = VALID_DAYS) {
    return this.generateFutureScheduleWithVendorSchedule(
      numberOfFutureDeliveries,
      {
        hiddenSkipped: true,
        userSkipped: true,
      },
      orgBillingDays
    );
  }

  calculateCurrentOrderCycleIndex(): number {
    if (this.orderCycleIndex !== undefined && this.orderCycleIndex !== null) {
      return this.orderCycleIndex;
    }

    const lastBilledCycle = this.getLastBilledCycle();

    // If there was no billed cycle, then we are in the first order of the cycle. Since order cycle index represents the most recent order index in the past, we return the payment frequency multiple - 1.
    if (!lastBilledCycle) {
      return this.paymentFrequencyMultiple - 1;
    }

    const now = nowUTC();
    const pastEvents = lastBilledCycle.events.filter(event => event.date <= now);

    // If there were no past events, then we are in the first order of the cycle. Since order cycle index represents the most recent order index in the past, we return the payment frequency multiple - 1.
    if (pastEvents.length === 0) {
      return this.paymentFrequencyMultiple - 1;
    }

    const mostRecentEvent = pastEvents[pastEvents.length - 1];

    return mostRecentEvent.index - lastBilledCycle.startIndex;
  }

  isCurrentlyInPrepaidCycle(): boolean {
    const currentOrderIndex = this.orderCycleIndex ?? this.calculateCurrentOrderCycleIndex();

    if (currentOrderIndex < 0) {
      return false;
    }

    const lastBilledCycle = this.getLastBilledCycle();

    if (!lastBilledCycle) {
      return false;
    }

    // Check if current order index is within the range of the payment frequency
    return currentOrderIndex < this.paymentFrequencyMultiple - 1;
  }

  isLastOrderOfPrepaidCycle(): boolean {
    const orderIndex = this.orderCycleIndex ?? this.calculateCurrentOrderCycleIndex();

    if (orderIndex < 0) {
      return false;
    }

    const lastBilledCycle = this.getLastBilledCycle();

    if (!lastBilledCycle) {
      return false;
    }

    return orderIndex === this.paymentFrequencyMultiple - 1;
  }

  isPrepaid(): boolean {
    return this.deliveryFrequencyMultiple !== this.paymentFrequencyMultiple;
  }

  private generateFutureScheduleWithVendorSchedule = (
    numberOfFutureDeliveriesToGenerate: number | Date,
    skipFilterConfig: ISkip,
    orgBillingDays: string[]
  ): ScheduledEvent[] => {
    return this.generateFutureSchedule(numberOfFutureDeliveriesToGenerate, skipFilterConfig, orgBillingDays).map(
      schedule => ({
        ...schedule,
        date: this.vendorSchedule ? this.vendorSchedule.after(schedule.date, true)! : schedule.date,
      })
    );
  };

  private generateFutureSchedule(
    numberOfFutureDeliveriesToGenerate: number | Date,
    skipFilterConfig: ISkip,
    orgBillingDays: string[]
  ): ScheduledEvent[] {
    // based on current date
    const presentGeneratorIndex = this.getPresentScheduleGeneratorIndex();

    // apply alterations to count based on skips at computation time
    let finalDeliveryRRule = this.deliveryRRule;
    if (finalDeliveryRRule.options.count !== null) {
      const skipsToCalculateExtension = this.ignorePastIndicesWhenExtending
        ? this.skips.slice(presentGeneratorIndex)
        : this.skips;
      const amountToExtendCount =
        (this.ignorePastIndicesWhenExtending ? presentGeneratorIndex : 0) +
        skipsToCalculateExtension.filter(
          skip =>
            skip &&
            ((this.extendOnHiddenSkip && skip.hiddenSkipped) || (this.extendOnUserSkip && skip.userSkipped))
        ).length;
      const finalCount = finalDeliveryRRule.options.count + amountToExtendCount;

      finalDeliveryRRule = new RRule({
        ...finalDeliveryRRule.options,
        count: finalCount,
      });
    }

    let nextDates;
    if (typeof numberOfFutureDeliveriesToGenerate === "number") {
      const numberOfDatesToGenerate = this.getNumberOfFutureEventsToGenerate(
        presentGeneratorIndex,
        this.skips,
        numberOfFutureDeliveriesToGenerate,
        skipFilterConfig
      );
      nextDates = getNextDates(finalDeliveryRRule, numberOfDatesToGenerate, orgBillingDays, this.nowUponCreation);
    } else {
      nextDates = getNextDatesUntil(
        finalDeliveryRRule,
        numberOfFutureDeliveriesToGenerate,
        orgBillingDays,
        this.nowUponCreation
      );
    }

    return this.buildEventsFromDates(presentGeneratorIndex, nextDates, skipFilterConfig);
  }

  private buildEventsFromDates(
    presentGeneratorIndex: number,
    eventDates: Date[],
    skipFilterConfig: ISkip
  ): ScheduledEvent[] {
    const events = eventDates.map((eventDate, index) => {
      const indexFromScheduleStart = index + presentGeneratorIndex;
      const isSkipped = isEventSkipped(this.skips[indexFromScheduleStart]);

      return {
        indexFromScheduleStart,
        date: eventDate,
        isSkipped,
        paymentMultipleDueOnDate: -1, // Just a placeholder, we need better way for this
      };
    });

    // Filter out skipped events
    const skipRemove = genSkipRemove(skipFilterConfig);
    const unskippedEvents = events.filter(event => !skipRemove(this.skips[event.indexFromScheduleStart] || null));
    if (!unskippedEvents.length) {
      return [];
    }

    // Fill payment indicators
    const lastBilledCycle = this.getLastBilledCycle();
    const isLastBilledCycleSendNow =
      this.totalOrdersCount &&
      this.totalOrdersCount > 1 &&
      unskippedEvents[0].date.getTime() === lastBilledCycle?.startDate.getTime();

    if (lastBilledCycle) {
      for (const event of unskippedEvents) {
        if (event.date <= lastBilledCycle.endDate) {
          event.paymentMultipleDueOnDate = 0;
        }

        if (
          isLastBilledCycleSendNow &&
          !this.isPrepaid() &&
          event.date.getTime() === lastBilledCycle.endDate.getTime()
        ) {
          event.paymentMultipleDueOnDate = this.paymentFrequencyMultiple;
        }
      }
    }

    const nextBillingCycleStartDate =
      lastBilledCycle && !isLastBilledCycleSendNow
        ? this.deliveryRRule.after(lastBilledCycle.endDate, false)!
        : this.deliveryRRule.options.dtstart;
    let nextBillingCycleStartIndex = lastBilledCycle ? lastBilledCycle.endIndex + 1 : 0;

    if (this.isPrepaid() && isLastBilledCycleSendNow && this.orderCycleIndex !== undefined) {
      if (this.orderCycleIndex === 0) {
        nextBillingCycleStartIndex = this.paymentFrequencyMultiple - 1;
      } else {
        nextBillingCycleStartIndex =
          this.orderCycleIndex + 1 >= this.paymentFrequencyMultiple ? 0 : this.orderCycleIndex;
      }
    }

    const indexToPaymentMap = this.getIndexToPaymentMap(
      nextBillingCycleStartDate,
      nextBillingCycleStartIndex,
      unskippedEvents[unskippedEvents.length - 1].date
    );
    for (const event of unskippedEvents) {
      const eventPayment = indexToPaymentMap.get(event.indexFromScheduleStart);
      if (eventPayment !== undefined) {
        event.paymentMultipleDueOnDate = eventPayment;
      }
    }

    return unskippedEvents.map((scheduleEvent, index) => ({
      ...scheduleEvent,
      indexFromNext: index,
    }));
  }

  public getPresentScheduleGeneratorIndex(): number {
    return this.getPastRRuleOccurrences().length;
  }

  public getEventDateByIndex(scheduleIndex: number): Date {
    if (scheduleIndex < 0) {
      throw new RangeError(`Schedule index can't be negative`);
    }

    let date = this.deliveryRRule.options.dtstart;
    for (let i = 0; i < scheduleIndex; ++i) {
      date = this.deliveryRRule.after(date, false)!;
    }

    return date;
  }

  private getLastBilledCycle() {
    if (!this.billedCycleStartDate || !this.billedDeliveriesCount) {
      return undefined;
    }

    const between = this.deliveryRRule.between(
      this.deliveryRRule.options.dtstart,
      this.billedCycleStartDate,
      true
    );
    const billedCycleStartIndex = between.length - 1;

    let currentDeliveryDate = this.billedCycleStartDate;
    let currentDeliveryIndex = billedCycleStartIndex;
    let remainingDeliveryCount = this.billedDeliveriesCount;
    const cycleEvents: {
      date: Date;
      index: number;
      isSkipped: boolean;
    }[] = [];
    while (remainingDeliveryCount > 0) {
      const isSkipped = isEventSkipped(this.skips[currentDeliveryIndex]);
      if (!isSkipped) {
        // Delivery event is not skipped, so it consumed another delivery instance
        remainingDeliveryCount -= 1;
      }

      cycleEvents.push({
        date: currentDeliveryDate,
        index: currentDeliveryIndex,
        isSkipped,
      });

      if (remainingDeliveryCount === 0) {
        // We reached the time moment when all billed deliveries were consumed (accounting for skips) and now
        // currentDeliveryDate points to the last delivery date covered by the last bill, so we break the loop
        break;
      }

      currentDeliveryDate = this.deliveryRRule.after(currentDeliveryDate, false)!;
      currentDeliveryIndex += 1;
    }

    return {
      startDate: this.billedCycleStartDate,
      startIndex: billedCycleStartIndex,
      endDate: currentDeliveryDate,
      endIndex: currentDeliveryIndex,
      events: cycleEvents,
    };
  }

  /**
   * Calculates how many paid deliveries from the last billed cycle we have after the present moment.
   */
  public getRemainingPaidDeliveriesCount(): number {
    const lastBilledCycle = this.getLastBilledCycle();

    // If there was no billed cycle, then we don't have any paid deliveries
    if (!lastBilledCycle) {
      return 0;
    }

    // Calculate how many paid events we have from the last billing cycle that occur after the next delivery
    const [nextDelivery] = this.getFutureScheduledDeliveries(1);
    return lastBilledCycle.events.filter(
      event => !event.isSkipped && event.index >= nextDelivery.indexFromScheduleStart
    ).length;
  }

  private getIndexToPaymentMap(startDate: Date, startIndex: number, endDate: Date) {
    const deliveryDates = this.deliveryRRule.between(startDate, endDate, true);
    const deliveryEvents = deliveryDates.map((deliveryDate: any, index: number) => {
      const indexFromScheduleStart = startIndex + index;
      const isSkipped = isEventSkipped(this.skips[indexFromScheduleStart]);
      return {
        indexFromScheduleStart,
        date: deliveryDate,
        isSkipped,
      };
    });
    const unskippedDeliveryEvents = deliveryEvents.filter(event => !event.isSkipped);

    // Fill paymentMultipleDueOnDate on events that are not skipped
    const deliveryEventsWithPaymentMarks = unskippedDeliveryEvents.map((event, index) => {
      const isPaymentDate = index % this.paymentFrequencyMultiple === 0;
      const paymentMultipleDueOnDate = isPaymentDate ? this.paymentFrequencyMultiple : 0;
      return {
        ...event,
        paymentMultipleDueOnDate,
      };
    });

    // Convert events to map: index -> paymentMultipleDueOnDate
    const indexToPaymentMap = new Map<number, number>();
    for (const event of deliveryEventsWithPaymentMarks) {
      indexToPaymentMap.set(event.indexFromScheduleStart, event.paymentMultipleDueOnDate);
    }

    return indexToPaymentMap;
  }

  private getPastRRuleOccurrences() {
    const pastRRuleOccurrences = this.deliveryRRule.between(
      this.deliveryRRule.options.dtstart,
      this.nowUponCreation,
      true
    );

    // since past deliveries query is inclusive need to check that it doesn't include today
    const lastItemFromPast = last(pastRRuleOccurrences);
    if (lastItemFromPast && +toUTCDate(lastItemFromPast) === +this.nowUponCreation) {
      pastRRuleOccurrences.pop();
    }

    return pastRRuleOccurrences;
  }

  private getNumberOfFutureEventsToGenerate(
    presentIndex: number,
    skipArr: SkipArrValue[],
    numberNeeded: number,
    skipFilterConfig: ISkip
  ): number {
    // "true" indexes will push out number of events to generate
    const removedIndices: boolean[] = skipArr.slice(presentIndex).map(genSkipRemove(skipFilterConfig));

    let numberOfEventsGenerated = 0;
    let presentNeededThatHaveBeenCreated = 0;
    while (presentNeededThatHaveBeenCreated < numberNeeded) {
      const isSkipped = removedIndices[numberOfEventsGenerated];

      if (!isSkipped) {
        presentNeededThatHaveBeenCreated++;
      }

      numberOfEventsGenerated++;
    }

    return numberOfEventsGenerated;
  }
}
