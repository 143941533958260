import { isArray, isNumber, pick } from "lodash";
import { RRule, Frequency as RRuleFrequency, Options as RRuleOptions } from "rrule";

import { toUTCDateLuxon } from "../../../utils/dateUtils";

export type RRuleAnchor = Partial<
  Pick<RRuleOptions, "bysecond" | "byminute" | "byhour" | "byweekday" | "bymonthday" | "bymonth" | "byyearday">
>;

// need to do this in order to take into account month overflow (past 28th)
// otherwise you actually skip months
// https://stackoverflow.com/questions/35757778/rrule-for-repeating-monthly-on-the-31st-or-closest-day
export function getCorrectedRRule(rruleOptions: Partial<RRuleOptions>, anchor: RRuleAnchor): Readonly<RRule> {
  const clonedOptions: Partial<RRuleOptions> = pick(rruleOptions, ["dtstart", "freq", "interval", "count"]);
  if (clonedOptions.dtstart && clonedOptions.freq !== undefined) {
    Object.assign<Partial<RRuleOptions>, Partial<RRuleOptions>>(
      clonedOptions,
      getCorrectedRRuleProperties(clonedOptions.dtstart, clonedOptions.freq, anchor)
    );
  }

  return Object.freeze(new RRule(clonedOptions));
}

function getCorrectedRRuleProperties(
  targetDate: Date,
  frequency: RRuleFrequency,
  anchor: RRuleAnchor
): Partial<RRuleOptions> & { freq: RRuleFrequency } {
  const utcTargetDate = toUTCDateLuxon(targetDate);

  const clonedOptions: Partial<RRuleOptions> & { freq: RRuleFrequency } = {
    freq: frequency,
  };

  // Apply anchors (all but bymonthday)
  clonedOptions.byweekday = anchor.byweekday ?? null;
  clonedOptions.bymonth = anchor.bymonth ?? null;
  clonedOptions.byyearday = anchor.byyearday ?? null;

  // Clear previously set values for monthday anchor
  // (bysetpos is not necessarily for monthday, but we use it only there)
  clonedOptions.bysetpos = null;
  clonedOptions.bymonthday = null;

  // Set up the time to the target date time or to an anchor if exists.
  clonedOptions.byhour = anchor.byhour ?? null;
  clonedOptions.byminute = anchor.byminute ?? null;
  clonedOptions.bysecond = anchor.bysecond ?? null;

  let anchorByMonthDay: number | undefined;
  if (isNumber(anchor.bymonthday)) {
    anchorByMonthDay = anchor.bymonthday;
  } else if (isArray(anchor.bymonthday) && anchor.bymonthday.length > 0) {
    anchorByMonthDay = anchor.bymonthday[0];
  }
  const targetDay = anchorByMonthDay ?? utcTargetDate.get("day");

  if (
    (frequency === RRuleFrequency.YEARLY &&
      utcTargetDate.get("day") === 29 &&
      utcTargetDate.get("month") === 2) ||
    (anchorByMonthDay === 29 && (anchor.bymonth === 2 || (isArray(anchor.bymonth) && anchor.bymonth[0] === 2)))
  ) {
    // handle leap year to be March 1st on non-leap years
    clonedOptions.byyearday = [60];
  } else if (frequency === RRuleFrequency.MONTHLY || isNumber(anchorByMonthDay)) {
    clonedOptions.bymonthday = anchorByMonthDay;

    // handle "overflow" dates as relative
    const MIN_MONTH_LENGTH = 28;

    const monthPotentialOverflow = targetDay - MIN_MONTH_LENGTH;
    if (monthPotentialOverflow > 0) {
      clonedOptions.bysetpos = [-1];
      const bymonthday: number[] = [MIN_MONTH_LENGTH - 1]; // not sure why but per demo this was necessary https://jakubroztocil.github.io/rrule/

      for (let i = 0; i <= monthPotentialOverflow; i++) {
        bymonthday.push(i + MIN_MONTH_LENGTH);
      }

      clonedOptions.bymonthday = bymonthday;
    }
  }

  return clonedOptions;
}
