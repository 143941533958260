import { isNil, last, sum } from "lodash";

import { generateRandomString } from "@smartrr/shared/functions";

import { unformatMoney } from "./formatMoney";
import { getPricingPolicyCycleAdjustment } from "./sharedTranslations/pricingPolicy";
import { OrgUtils } from "../entities/Organization";
import { IPurchaseState } from "../entities/PurchaseState";
import {
  IPricingPolicyCycleAdjustment,
  IPurchaseStateLineItemPricingPolicy,
} from "../entities/PurchaseState/CustomerPurchaseLineItem";
import { RRuleManager } from "../entities/Schedule/RRuleManager";
import {
  DiscountTargetType,
  DiscountValueType,
  IDiscount,
  IOrderLineItemDiscount,
  IPurchaseStateDiscount,
  IPurchaseStateLineItemDiscount,
} from "../interfaces/Discount";
import { MoneyV2 } from "../shopifyGraphQL/api";
import {
  SubscriptionContractFromQuery,
  SubscriptionLineItemFromQuery,
} from "../shopifyGraphQL/subscriptionContracts";
import { SubscriptionDraftFromQuery } from "../shopifyGraphQL/subscriptionDrafts";

export function getContractAllLinesCalculations(
  contract: SubscriptionContractFromQuery | SubscriptionDraftFromQuery,
  contractDiscounts: IPurchaseStateDiscount[],
  contractLineItems: SubscriptionLineItemFromQuery[]
) {
  const totalShipping = isNil(contract.deliveryPrice)
    ? 0
    : unformatMoney(contract.deliveryPrice?.amount, contract.currencyCode);

  // Calculate total across all line items before discount
  const totalFromLineItems = contractLineItems.reduce(
    (acc, lineItem) => acc + getLineItemBasePrice(lineItem) * lineItem.quantity,
    0
  );

  // Calculate total across all line items after pricing policy adjustments and all applied discounts
  // NOTE: lineDiscountedPrice contains value multiplied by quantity, so we don't need multiplication here
  const totalLineItemsAfterDiscount = contractLineItems.reduce(
    (acc, lineItem) =>
      acc + unformatMoney(lineItem.lineDiscountedPrice.amount, lineItem.lineDiscountedPrice.currencyCode),
    0
  );

  const totalShippingDiscount = getTotalShippingDiscount(contract.deliveryPrice, contractDiscounts);
  const totalLineItemsDiscount = totalFromLineItems - totalLineItemsAfterDiscount;
  const totalDiscount = totalShippingDiscount + totalLineItemsDiscount;
  const totalEstimatedNet = totalShipping + totalFromLineItems - totalDiscount;

  return {
    totalFromLineItems,
    totalLineItemsDiscount,
    totalLineItemsAfterDiscount,
    totalShipping,
    totalShippingDiscount,
    totalDiscount,
    totalEstimatedNet,
  };
}

function getLineItemBasePrice(lineItem: SubscriptionLineItemFromQuery) {
  return lineItem.pricingPolicy
    ? unformatMoney(lineItem.pricingPolicy.basePrice.amount, lineItem.pricingPolicy.basePrice.currencyCode)
    : unformatMoney(lineItem.currentPrice.amount, lineItem.currentPrice.currencyCode);
}

export function getTotalShippingDiscount(
  deliveryPrice: MoneyV2 | null | undefined,
  contractDiscounts: IPurchaseStateDiscount[]
) {
  if (isNil(deliveryPrice)) {
    return 0;
  }

  const totalShipping = unformatMoney(deliveryPrice.amount, deliveryPrice.currencyCode);

  const shippingDiscounts = contractDiscounts.filter(isShippingDiscount);

  return Math.round(
    sum(
      shippingDiscounts.map(discount =>
        isPercentageDiscount(discount) ? totalShipping * (discount.value / 100) : discount.value
      )
    )
  );
}

export const REWARD_CODE_PREFIX = "REWARD-";
export const PREPAID_SUBSCRIPTION_DISCOUNT_CODE = "Prepaid";
export const PREPAID_SHIPPING_DISCOUNT_CODE = "Prepaid Shipping";
const HASH_LENGTH = 5;

export function generateRewardCode(email: string, codePrefix = REWARD_CODE_PREFIX, seedNumber?: number) {
  // seedNumber is used for verifying the code
  const random00000to99999 = seedNumber ?? Math.floor(Math.random() * 10 ** HASH_LENGTH);

  // these are being used for discount codes, so we don't need them to be super elaborate
  // first 5 digits are random, last 5 are a hash of the email and the random number
  // and we can use the random number to verify the code
  const randString = generateRandomString(email, random00000to99999);

  return `${codePrefix}${random00000to99999}${randString}`;
}

export function isActiveDiscount<
  T extends { rejectionReason?: string; recurringCycleLimit?: number; usageCount?: number },
>(discount: T) {
  const hasUsesRemaining =
    !isNil(discount.recurringCycleLimit) && !isNil(discount.usageCount)
      ? discount.usageCount < discount.recurringCycleLimit
      : true;

  return !discount.rejectionReason && hasUsesRemaining;
}

export function isActiveShippingDiscount(discount: IDiscount) {
  return isShippingDiscount(discount) && isActiveDiscount(discount);
}

export function isShippingDiscount(discount: IDiscount) {
  return discount.targetType === DiscountTargetType.SHIPPING_LINE;
}

export function isRewardsDiscount(code: string) {
  return new RegExp(`^${REWARD_CODE_PREFIX}[a-zA-Z0-9]{10}$`).test(code.trim());
}

export function isValidRewardsDiscount(code: string, email: string) {
  if (isRewardsDiscount(code)) {
    // check if the code is valid - checking against user's email
    const controlHash = generateRewardCode(email, REWARD_CODE_PREFIX, +code.slice(7, 12));
    return controlHash === code;
  }
  return false;
}

export function isPercentageDiscount(discount: IDiscount) {
  return discount.valueType === DiscountValueType.PERCENTAGE;
}

export function applyDiscounts<T extends IOrderLineItemDiscount | IPurchaseStateLineItemDiscount>(
  price: number,
  discounts: T[],
  offset = 0
) {
  return Math.round(
    discounts.reduce((acc, discount) => {
      if (!isNil((discount as IOrderLineItemDiscount).calculatedValue)) {
        return Math.max(0, acc - (discount as IOrderLineItemDiscount).calculatedValue);
      }

      // `recurringCycleLimit` === `undefined` means we're looking at an order item discount
      // `recurringCycleLimit` === `null` means the discount applies to all future cycles
      // If either of the above two are true skip this check
      if (!isNil((discount as IPurchaseStateLineItemDiscount).recurringCycleLimit)) {
        const cpsLineItemDiscount = discount as IPurchaseStateLineItemDiscount;
        if (
          cpsLineItemDiscount.rejectionReason ||
          cpsLineItemDiscount.usageCount + offset >= cpsLineItemDiscount.recurringCycleLimit!
        ) {
          return acc;
        }
      }

      return discount.valueType === DiscountValueType.FIXED
        ? Math.max(0, acc - discount.value)
        : acc - (discount.value / 100) * acc;
    }, price)
  );
}

/**
 * Selects a price with pricing policy discount for the specified delivery cycle.
 * This price is in fact a "subscription price". It accounts for benefits we offer to subscriber, but it
 * doesn't account for Shopify discounts that customer might have on top of subscription.
 *
 * NOTE: it's not quite accurate to pass skdIdx as cycle, because if client skips certain orders,
 * this will not be accounted by the pricing policy. This was tracked as Issue 5 in the ideas doc.
 */
export function selectPricingPolicyComputedPriceForCycle(
  pricingPolicy: IPurchaseStateLineItemPricingPolicy,
  cycle: number
) {
  const appliedAdjustment = last(
    pricingPolicy.cycleDiscounts.filter(cycleDiscount => cycleDiscount.afterCycle <= Math.max(cycle, 0))
  );

  return appliedAdjustment
    ? unformatMoney(appliedAdjustment.computedPrice.amount, appliedAdjustment.computedPrice.currencyCode)
    : unformatMoney(pricingPolicy.basePrice.amount, pricingPolicy.basePrice.currencyCode);
}

/**
 * Selects a price adjustment that the pricing policy defines for specified delivery cycle.
 * This adjustment shows benefits that we offer to subscriber, but it doesn't account for Shopify discounts
 * that customer might have on top of subscription.
 */
export function selectPricingPolicyCycleAdjustmentForCycle(
  pricingPolicy: IPurchaseStateLineItemPricingPolicy,
  cycle: number
): IPricingPolicyCycleAdjustment | undefined {
  const appliedAdjustment = last(
    pricingPolicy.cycleDiscounts.filter(adjustment => adjustment.afterCycle <= Math.max(cycle, 0))
  );

  return appliedAdjustment
    ? getPricingPolicyCycleAdjustment(appliedAdjustment.adjustmentType, appliedAdjustment.adjustmentValue)
    : undefined;
}

/**
 * Returns schedule index (skdIdx) of the nearest scheduled delivery. Scheduled (vs Actual) means that
 * we don't care about skipped shipments.
 *
 * NOTE: if next billing date of the first order is in the future, this index will be 0.
 * Be careful if you try to subtract -1 from it to get previous pricing cycle!
 */
export function getUpcomingPricingCycle(customerPurchaseState: IPurchaseState) {
  const { schedule, organization } = customerPurchaseState;
  if (schedule && organization) {
    const rruleManager = RRuleManager.fromJson(schedule, OrgUtils.getBillingSchedule(organization));
    return rruleManager.getPresentScheduleGeneratorIndex();
  }

  return 0;
}
