import { last } from "lodash";
import { RRule } from "rrule";

import { incrementDateRecurByBillingDay, toUTCDate } from "../../../utils/dateUtils";
import { ISkip, SkipArrValue } from "../RRuleManagerStructure";

function getStartDate(rrule: Readonly<RRule>, afterDate?: Date) {
  const startDate = toUTCDate(afterDate ?? rrule.options.dtstart);
  if (!startDate) {
    throw `no afterDate given, afterDate: ${afterDate}, rrule: ${rrule}`;
  }
  return startDate;
}

export function getNextDatesUntil(
  rrule: Readonly<RRule>,
  until: Date,
  orgBillingDays: string[],
  afterDate?: Date
) {
  const startDate = getStartDate(rrule, afterDate);
  const dates: Date[] = [];
  const untilTime = toUTCDate(until).getTime();
  let cur = toUTCDate(rrule.after(startDate, true)!);
  while (cur.getTime() > 0 && cur.getTime() <= untilTime) {
    cur = incrementDateRecurByBillingDay(orgBillingDays, cur);
    dates.push(cur);
    cur = toUTCDate(rrule.after(cur, false)!);
  }
  return dates;
}

export function getNextDates(
  rrule: Readonly<RRule>,
  count: number,
  orgBillingDays: string[],
  afterDate?: Date
): Date[] {
  let startDate = getStartDate(rrule, afterDate);

  // pushing start date forward if it lands on an invalid day
  startDate = incrementDateRecurByBillingDay(orgBillingDays, startDate);

  const dates: Date[] = [];
  for (let i = 0; i < count; i++) {
    let nextDate = rrule.after(last(dates) || startDate, i === 0);
    if (!nextDate) {
      break;
    }

    nextDate = incrementDateRecurByBillingDay(orgBillingDays, nextDate);

    nextDate = toUTCDate(nextDate);

    // stop if we've run out of new dates (equaling previous date after inclusive would count)
    if (nextDate === startDate) {
      break;
    }

    dates.push(nextDate);
  }

  return dates;
}

/**
 * Determines whether schedule event is skipped (by user or by the system).
 * Skip array item of null is used just as a filler (placeholder) to populate unskipped indexes.
 */
export function isEventSkipped(skip: ISkip | undefined | null): boolean {
  return !!skip && (skip.userSkipped || skip.hiddenSkipped);
}

export function genSkipRemove(skipFilter: ISkip) {
  return function skipRemove(skip: SkipArrValue) {
    if (skip?.hiddenSkipped && !skipFilter.hiddenSkipped) {
      return true;
    } else if (skip?.userSkipped && !skipFilter.userSkipped) {
      return true;
    }
    return false;
  };
}
